import React from "react"

export const bierdeckelCaptionText = (
  <React.Fragment>
    <p>
      Immer wieder stellen wir fest, dass viele Betriebe mit der Kalkulation
      ihrer Leistungen Probleme haben und sich mit Ihren Preisen daher oftmals
      einfach nach dem Mitbewerbern richten, ohne zu wissen, ob sie dadurch
      drauflegen oder vielleicht sogar zu teuer anbieten. Mit unserem Seminar-
      und Beratungsangebot möchten wir Ihnen zeigen, dass das auf einfache Weise
      auch anders geht.
    </p>{" "}
    <p>
      Handwerker sind Praktiker und doch verlassen Sie sich bei der Kalkulation
      oft genug auf ihr Bauchgefühl, rechnen nach der Methode „Pi mal Daumen“.
      Die Folge: Über 80 % der Handwerker verschenken viel Geld durch zu geringe
      Stundensätze, falsche Materialaufschläge, hohen Zeitaufwand und
      Personaleinsatz. Das muss nicht sein!
    </p>
    <p>
      Eine einfache Kalkulation mit nur 3 Zahlen, die Platz auf einem Bierdeckel
      hat garantiert Ihren Gewinn bei jedem Auftrag.
    </p>
  </React.Fragment>
)

export const reportText = (
  <React.Fragment>
    <p>
      Wir bieten Ihnen eine anschauliche Übersicht über die Entwicklung Ihres
      Betriebs. Sie entscheiden, ob Sie den Report monatlich oder quartalsweise
      erhalten möchten. Über ein Cockpit und das Ampelsystem steuern Sie die
      Zukunft Ihres Handwerksbetriebes. So behalten Sie jederzeit die Übersicht
      über die Entwicklung Ihres gesamten Betriebes.
    </p>
  </React.Fragment>
)
