import React, { Component } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight"
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft"
import { successColor } from "../../static/styles/colors"
import "slick-carousel/slick/slick.css"

class ReportSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navigationBigSlider: null,
      navigationSmallSlider: null,
    }
  }

  componentDidMount() {
    this.setState({
      navigationBigSlider: this.sliderBig,
      navigationSmallSlider: this.sliderSmall,
    })
  }

  render() {
    const settingsSliderBig = {
      asNavFor: this.state.navigationSmallSlider,
      ref: slider => (this.sliderBig = slider),
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false,
          },
        },
      ],
    }

    const settingsSliderSmall = {
      asNavFor: this.state.navigationBigSlider,
      ref: slider => (this.sliderSmall = slider),
      slidesToShow: 3,
      swipeToSlide: true,
      focusOnSelect: true,
      centerMode: true,
      arrows: false,
    }

    return (
      <div className="container-fluid pt-5 pb-5">
        <h2 className="text-uppercase text-center fw-bold text-light">
          Bierdeckel - <span className="text-success">Report</span>
        </h2>
        <br />
        <br />
        <AlignCenter>
          <Slider {...settingsSliderBig}>
            <div className="w-100">
              <div
                style={{ minWidth: "275px" }}
                className="w-25 d-block mx-auto"
              >
                <StaticImage
                  src="../../images/kaufmaennischeBegleitung/slider/bierdeckel-slide-2.png"
                  alt="Report Seite 1"
                  layout="fullWidth"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </div>
            </div>
            <div className="w-100">
              <div
                style={{ minWidth: "275px" }}
                className="w-25 d-block mx-auto"
              >
                <StaticImage
                  src="../../images/kaufmaennischeBegleitung/slider/bierdeckel-slide-1.png"
                  alt="Report Seite 2"
                  layout="fullWidth"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </div>
            </div>
            <div className="w-100">
              <div
                style={{ minWidth: "275px" }}
                className="w-25 d-block mx-auto"
              >
                <StaticImage
                  src="../../images/kaufmaennischeBegleitung/slider/bierdeckel-slide-3.png"
                  alt="Report Seite 3"
                  layout="fullWidth"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </div>
            </div>
            <div className="w-100">
              <div
                style={{ minWidth: "275px" }}
                className="w-25 d-block mx-auto"
              >
                <StaticImage
                  src="../../images/kaufmaennischeBegleitung/slider/bierdeckel-slide-4.png"
                  alt="Report Seite 4"
                  layout="fullWidth"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </div>
            </div>
          </Slider>
        </AlignCenter>
        <AlignCenter>
          <Slider {...settingsSliderSmall} className="d-md-block d-none">
            <div className="w-100">
              <SmallImageContainer className="w-25 d-block mx-auto">
                <StaticImage
                  src="../../images/kaufmaennischeBegleitung/slider/small/bierdeckel-slide-2_small.png"
                  alt="Vorschau Report Seite 1"
                  layout="fullWidth"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </SmallImageContainer>
            </div>
            <div className="w-100">
              <SmallImageContainer className="w-25 d-block mx-auto">
                <StaticImage
                  src="../../images/kaufmaennischeBegleitung/slider/small/bierdeckel-slide-1_small.png"
                  alt="Vorschau Report Seite 2"
                  layout="fullWidth"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </SmallImageContainer>
            </div>
            <div className="w-100">
              <SmallImageContainer className="w-25 d-block mx-auto">
                <StaticImage
                  src="../../images/kaufmaennischeBegleitung/slider/small/bierdeckel-slide-3_small.png"
                  alt="Vorschau Report Seite 3"
                  layout="fullWidth"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </SmallImageContainer>
            </div>
            <div className="w-100">
              <SmallImageContainer className="w-25 d-block mx-auto">
                <StaticImage
                  src="../../images/kaufmaennischeBegleitung/slider/small/bierdeckel-slide-4_small.png"
                  alt="Vorschau Report Seite 4"
                  layout="fullWidth"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </SmallImageContainer>
            </div>
          </Slider>
        </AlignCenter>
      </div>
    )
  }
}

const SampleNextArrow = props => {
  return (
    <div
      className="position-absolute"
      onKeyDown={props.onClick}
      onClick={props.onClick}
      role="button"
      tabIndex={0}
      style={{
        cursor: "pointer",
        right: "25%",
        top: "50%",
        WebkitTransform: "translate(0, -50%)",
        msTransform: "translate(0, -50%)",
        transform: "translate(0, -50%)",
      }}
    >
      <MdKeyboardArrowRight
        size="150px"
        className="me-3"
        color={successColor}
      />
    </div>
  )
}

const SamplePrevArrow = props => {
  return (
    <div
      className="position-absolute"
      onKeyDown={props.onClick}
      onClick={props.onClick}
      role="button"
      tabIndex={0}
      style={{
        zIndex: "10",
        cursor: "pointer",
        left: "25%",
        top: "50%",
        WebkitTransform: "translate(0, -50%)",
        msTransform: "translate(0, -50%)",
        transform: "translate(0, -50%)",
      }}
    >
      <MdKeyboardArrowLeft size="150px" className="me-3" color={successColor} />
    </div>
  )
}

const SmallImageContainer = styled.div`
  min-width: 100px;
  @media all and (min-width: 576) {
    display: none;
  }
`

const AlignCenter = styled.div`
  margin-bottom: 50px;
  & > div > div > div {
    display: flex;
    align-items: center;
  }
`

export default ReportSlider
