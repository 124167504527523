import React from "react"
import Layout from "../components/common/layout"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import HeroSection from "../components/hero-section"
import ContactFooter from "../components/contact-footer"
import PartnerSlider from "../components/partner-slider"
import CircleProgress from "../components/circle-progress"
import CardSection from "../components/card-section"
import VideReviews from "../components/video-reviews"
import VideoWidthCaption from "../components/video-with-caption"
import stepArrowImageRight from "../images/kaufmaennischeBegleitung/arrowRight.png"
import stepArrowImageDown from "../images/kaufmaennischeBegleitung/arrowDown.png"
import ReportSlider from "../components/kaufmaennische_begleitung/reportSlider"
import ReportStep1 from "../images/svg/kaufmaennische_begleitung/step1.svg"
import ReportStep2 from "../images/svg/kaufmaennische_begleitung/step2.svg"
import ReportStep3 from "../images/svg/kaufmaennische_begleitung/step3.svg"
import { FaArrowRight } from "@react-icons/all-files/Fa/FaArrowRight"
import {
  BGLinearGradient,
  Separator,
  SeparatorColorFull,
  ButtonPrimary,
} from "../components/common/styledComponentsHelper"
import {
  bierdeckelCaptionText,
  reportText,
} from "../static/text/kaufmaennischeBegleitungContent"
import {
  secondaryColor,
  darkColor,
  successColor,
} from "../static/styles/colors"
import { videoCaptionText, customerReview } from "../static/text/commonContent"
import { Link } from "gatsby"

export const Head = () => (
  <>
    <title>Kaufmännische Begleitung | Fachhandwerk360</title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const KaufmaennischeBegleitung = () => (
  <Layout>
    <HeroSection
      desktopImg="kaufmaennischeBegleitung_banner_desktop.png"
      mobileImg="kaufmaennischeBegleitung_banner_mobile.png"
      headlineText="Kaufmännische Begleitung"
      responsiveHeadline="Kaufmännische Begleitung"
      subheadlineText="Mehr Gewinn mit professioneller Begleitung"
      captionText=""
    />
    <BGLinearGradient>
      <Separator />
      <div className="container p-sm-5 p-3">
        <VideoWidthCaption
          headlineText={
            <>
              Wie komme ich zu mehr{" "}
              <span className="text-success">Gewinn?</span>
            </>
          }
          videoURL="https://www.youtube-nocookie.com/embed/zzSkQNJyqPQ?rel=0"
          videoCaption="Die Bierdeckelkalkulation - So funktioniert sie!"
          captionText={videoCaptionText}
        />
      </div>
      <div className="container p-sm-5 p-3 w-75">
        <div className="row align-items-center">
          <div className="col-lg-4 p-3 pe-0 ms-auto d-block">
            <StaticImage
              src="../images/kaufmaennischeBegleitung/bierdeckelFHW.png"
              layout="fullWidth"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Bierdeckel mit Fachhandwerk360-Logo"
              placeholder="none"
              className="mx-auto"
              style={{ maxWidth: "500px" }}
            />
          </div>
          <div className="col-lg-8">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 text-sm-start text-center align-middle text-start">
                    <h2 className="text-uppercase fw-bold text-success ms-auto">
                      Bierdeckel<span className="d-sm-none d-inline"> </span>
                      kalkulation
                    </h2>
                    <div className="text-light mt-4 fs-5">
                      {bierdeckelCaptionText}
                    </div>
                    <Link to="/online-seminar/organic">
                      <ButtonPrimary>
                        Jetzt kostenlos Anmelden
                        <FaArrowRight className="ms-3" color={successColor} />
                      </ButtonPrimary>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Separator />
      <BGLinearGradientVideo>
        <div className="container p-sm-5 p-4">
          <h2 className="text-center fw-bold text-light pt-4 text-uppercase">
            Das sagen unsere <span className="text-success">Kunden</span>
          </h2>
          <VideoWidthCaption
            headlineText={
              <>
                Endlich mehr <span className="text-success">Gewinn!</span>
              </>
            }
            videoURL="https://www.youtube-nocookie.com/embed/KzZwXMKM3zo?rel=0"
            videoCaption="Die Bierdeckelkalkulation - Das sagen unsere Handwerkskunden!"
            captionText={customerReview}
          />
          <VideReviews />
        </div>
      </BGLinearGradientVideo>
      <CircleProgress />
      <Separator />
      <BGCard>
        <Separator />
        <h2 className="text-light text-center text-uppercase fw-bold">
          <span className="text-success">Bierdeckelkalkulation</span>
        </h2>
        <CardSection />
        <Separator />
      </BGCard>
      <ReportSlider />
      <div className="container pb-md-5">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-light fs-5 text-center">{reportText}</div>
          </div>
        </div>
      </div>
      <SeparatorColorFull />
      <h2 className="text-light text-center text-uppercase fw-bold">
        Bierdeckel Report in <span className="text-success">3 Schritten</span>
      </h2>
      <Separator />
      <div className="container">
        <div className="row justify-content-center align-items-center p-sm-0 p-3">
          <div className="col-lg-2 col-sm-7 p-lg-4 p-5 position-relative">
            <AfterArrow>
              <a
                href="https://fachhandwerk360.appointlet.com/"
                target="_blank"
                rel="noreferrer"
              >
                <ReportStep1 />
              </a>
            </AfterArrow>
          </div>
          <div className="col-lg-2 col-sm-7 p-lg-4 p-5 position-relative">
            <AfterArrow>
              <ReportStep2 />
            </AfterArrow>
          </div>
          <div className="col-lg-2 col-sm-7 p-lg-4 p-5 position-relative">
            <AfterArrow>
              <ReportStep3 />
            </AfterArrow>
          </div>
          <div className="col-lg-3 col-sm-8 position-relative">
            <StaticImage
              src="../images/kaufmaennischeBegleitung/step4.png"
              layout="fullWidth"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Bierdeckel-Report Schritt 4"
            />
          </div>
        </div>
      </div>
      <Separator />
      <ContactFooter />
      <PartnerSlider />
    </BGLinearGradient>
  </Layout>
)

const AfterArrow = styled.div`
  ::after {
    content: "";
    position: absolute;
    left: 91%;
    width: 30%;
    height: 30%;
    background-image: url(${stepArrowImageRight});
    background-repeat: no-repeat;
    z-index: 1;
    background-size: contain;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  @media screen and (max-width: 992px) {
    ::after {
      background-image: url(${stepArrowImageDown});
      top: 88%;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
  }
`

const BGLinearGradientVideo = styled.div`
  background: linear-gradient(
    90deg,
    transparent 0%,
    ${secondaryColor} 10%,
    ${secondaryColor} 90%,
    transparent 100%
  );
`

const BGCard = styled.div`
  background-color: ${darkColor};
`

export default KaufmaennischeBegleitung
